import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import WgptBotWorkflowStagesAPI from '../../api/wgpt/botWorkflowStages';
import types from '../mutation-types';
import { throwErrorMessage } from '../utils/api';

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
  },
};

export const getters = {
  getStages: $state => workflowId => {
    return $state.records.filter(record => record.workflowId === workflowId);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getStage: $state => (workflowId, id) => {
    return $state.records.find(
      record => record.workflowId === workflowId && record.id === id
    );
  },
};

export const actions = {
  get: async ({ commit }, { botId, workflowId }) => {
    commit(types.SET_WGPT_BOT_WORKFLOW_STAGE_UI_FLAG, { isFetching: true });
    try {
      const wgptBotWorkflowsAPI = new WgptBotWorkflowStagesAPI(
        botId,
        workflowId
      );
      const response = await wgptBotWorkflowsAPI.get();
      const data = response.data.map(card => {
        card.workflowId = workflowId;
        return card;
      });
      commit(types.SET_WGPT_BOT_WORKFLOW_STAGES, { workflowId, data });
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WGPT_BOT_WORKFLOW_STAGE_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, { botId, workflowId, ...botObj }) => {
    commit(types.SET_WGPT_BOT_WORKFLOW_STAGE_UI_FLAG, { isCreating: true });
    try {
      const wgptBotWorkflowsAPI = new WgptBotWorkflowStagesAPI(
        botId,
        workflowId
      );
      const { data } = await wgptBotWorkflowsAPI.create(botObj);
      data.workflowId = workflowId;
      commit(types.ADD_WGPT_BOT_WORKFLOW_STAGE, data);
      return data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_WORKFLOW_STAGE_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  update: async ({ commit }, { botId, workflowId, id, ...updateObj }) => {
    commit(types.SET_WGPT_BOT_WORKFLOW_STAGE_UI_FLAG, { isUpdating: true });
    try {
      const wgptBotWorkflowsAPI = new WgptBotWorkflowStagesAPI(
        botId,
        workflowId
      );
      const { data } = await wgptBotWorkflowsAPI.update(id, updateObj);
      data.workflowId = workflowId;
      commit(types.EDIT_WGPT_BOT_WORKFLOW_STAGE, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_WORKFLOW_STAGE_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, { botId, workflowId, id }) => {
    commit(types.SET_WGPT_BOT_WORKFLOW_STAGE_UI_FLAG, { isDeleting: true });
    try {
      const wgptBotWorkflowsAPI = new WgptBotWorkflowStagesAPI(
        botId,
        workflowId
      );
      await wgptBotWorkflowsAPI.delete(id);
      commit(types.DELETE_WGPT_BOT_WORKFLOW_STAGE, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_WORKFLOW_STAGE_UI_FLAG, { isDeleting: false });
    }
  },
  show: async ({ commit }, { botId, workflowId, id }) => {
    commit(types.SET_WGPT_BOT_WORKFLOW_STAGE_UI_FLAG, { isFetchingItem: true });
    try {
      const wgptBotWorkflowsAPI = new WgptBotWorkflowStagesAPI(
        botId,
        workflowId
      );
      const { data } = await wgptBotWorkflowsAPI.show(id);
      data.workflowId = workflowId;
      commit(types.ADD_WGPT_BOT_WORKFLOW_STAGE, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_WORKFLOW_STAGE_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },
  clear: ({ commit }) => {
    commit(types.EMPTY_WGPT_BOT_WORKFLOW_STAGES);
  },
};

export const mutations = {
  [types.SET_WGPT_BOT_WORKFLOW_STAGE_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.EMPTY_WGPT_BOT_WORKFLOW_STAGES]($state) {
    $state.records = [];
  },
  [types.ADD_WGPT_BOT_WORKFLOW_STAGE]: MutationHelpers.setSingleRecord,
  [types.SET_WGPT_BOT_WORKFLOW_STAGES]($state, { workflowId, data }) {
    const ids = data.map(({ id }) => id);
    $state.records = $state.records
      .filter(record => {
        if (ids.includes(record.id)) return false;
        if (record.workflowId === workflowId) return false;
        return true;
      })
      .concat(
        ...data.map(d => {
          d.workflowId = workflowId;
          return d;
        })
      );
  },
  [types.EDIT_WGPT_BOT_WORKFLOW_STAGE]: MutationHelpers.update,
  [types.DELETE_WGPT_BOT_WORKFLOW_STAGE]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
