<script setup>
import { useAlert } from 'dashboard/composables';
import BaseSettingsHeader from '../components/BaseSettingsHeader.vue';
import AddAutomation from './add/Index.vue';
import SettingsLayout from '../SettingsLayout.vue';
import WgptAutomationRow from './components/WgptAutomationRow.vue';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStoreGetters, useStore } from 'dashboard/composables/store';
import { useRouter } from 'vue-router';

const router = useRouter();
const getters = useStoreGetters();
const store = useStore();
const { t } = useI18n();
const confirmDialog = ref(null);

const loading = ref({});
const showAddPopup = ref(false);
const showDeleteConfirmationPopup = ref(false);
const toggleModalTitle = ref(t('WGPT_AUTOMATIONS.TOGGLE.ACTIVATION_TITLE'));
const toggleModalDescription = ref(
  t('WGPT_AUTOMATIONS.TOGGLE.ACTIVATION_DESCRIPTION')
);
const selectedResponse = ref({});

const records = computed(() => getters['wgptAutomations/getAutomations'].value);
const uiFlags = computed(() => getters['wgptAutomations/getUIFlags'].value);

const deleteConfirmText = computed(
  () =>
    `${t('WGPT_AUTOMATIONS.DELETE.CONFIRM.YES')} ${selectedResponse.value.name}`
);

const deleteRejectText = computed(
  () =>
    `${t('WGPT_AUTOMATIONS.DELETE.CONFIRM.NO')} ${selectedResponse.value.name}`
);

const deleteMessage = computed(() => ` ${selectedResponse.value.name}?`);

onMounted(() => {
  store.dispatch('wgptAutomations/get');
});

const editAutomation = automation => {
  router.push({
    name: 'wgpt_automations_edit',
    params: {
      automationId: automation.id,
    },
  });
};

const openAddPopup = () => {
  showAddPopup.value = true;
};

const hideAddPopup = () => {
  showAddPopup.value = false;
};
const openDeletePopup = response => {
  showDeleteConfirmationPopup.value = true;
  selectedResponse.value = response;
};

const closeDeletePopup = () => {
  showDeleteConfirmationPopup.value = false;
};

const deleteAutomation = async id => {
  try {
    await store.dispatch('wgptAutomations/delete', id);
    useAlert(t('WGPT_AUTOMATIONS.DELETE.API.SUCCESS_MESSAGE'));
  } catch (error) {
    useAlert(t('WGPT_AUTOMATIONS.DELETE.API.ERROR_MESSAGE'));
  } finally {
    loading.value[selectedResponse.value.id] = false;
  }
};

const confirmDeletion = () => {
  loading.value[selectedResponse.value.id] = true;
  closeDeletePopup();
  deleteAutomation(selectedResponse.value.id);
};

const cloneAutomation = async id => {
  try {
    await store.dispatch('wgptAutomations/clone', id);
    useAlert(t('WGPT_AUTOMATIONS.CLONE.API.SUCCESS_MESSAGE'));
    store.dispatch('wgptAutomations/get');
  } catch (error) {
    useAlert(t('WGPT_AUTOMATIONS.CLONE.API.ERROR_MESSAGE'));
  } finally {
    loading.value[selectedResponse.value.id] = false;
  }
};

const toggleAutomation = async (automation, status) => {
  try {
    toggleModalTitle.value = status
      ? t('WGPT_AUTOMATIONS.TOGGLE.DEACTIVATION_TITLE')
      : t('WGPT_AUTOMATIONS.TOGGLE.ACTIVATION_TITLE');
    toggleModalDescription.value = status
      ? t('WGPT_AUTOMATIONS.TOGGLE.DEACTIVATION_DESCRIPTION', {
          automationName: automation.name,
        })
      : t('WGPT_AUTOMATIONS.TOGGLE.ACTIVATION_DESCRIPTION', {
          automationName: automation.name,
        });

    const ok = await confirmDialog.value.showConfirmation();
    if (ok) {
      await store.dispatch('wgptAutomations/update', {
        id: automation.id,
        active: !status,
      });
      const message = status
        ? t('WGPT_AUTOMATIONS.TOGGLE.DEACTIVATION_SUCCESFUL')
        : t('WGPT_AUTOMATIONS.TOGGLE.ACTIVATION_SUCCESFUL');
      useAlert(message);
    }
  } catch (error) {
    useAlert(t('WGPT_AUTOMATIONS.EDITOR.API.ERROR_MESSAGE'));
  }
};

const tableHeaders = computed(() => {
  return [
    t('WGPT_AUTOMATIONS.LIST.TABLE_HEADER.NAME'),
    t('WGPT_AUTOMATIONS.LIST.TABLE_HEADER.DESCRIPTION'),
    t('WGPT_AUTOMATIONS.LIST.TABLE_HEADER.ACTIVE'),
    t('WGPT_AUTOMATIONS.LIST.TABLE_HEADER.UPDATED_ON'),
  ];
});
</script>

<template>
  <SettingsLayout
    :no-records-message="$t('WGPT_AUTOMATIONS.LIST.404')"
    :no-records-found="!records.length"
    :is-loading="uiFlags.isFetching"
    :loading-message="$t('WGPT_AUTOMATIONS.LIST.LOADING')"
  >
    <template #header>
      <BaseSettingsHeader
        :title="$t('WGPT_AUTOMATIONS.HEADER')"
        :description="$t('WGPT_AUTOMATIONS.DESCRIPTION')"
        feature-name="wgpt_automations"
      >
        <template #actions>
          <woot-button
            color-scheme="primary"
            class-names="rounded-md"
            @click="openAddPopup"
          >
            <fluent-icon icon="add-circle" />
            <span class="button__content">
              {{ $t('WGPT_AUTOMATIONS.HEADER_BTN_TXT') }}
            </span>
          </woot-button>
        </template>
      </BaseSettingsHeader>
    </template>

    <template #body>
      <table class="min-w-full divide-y divide-slate-75 dark:divide-slate-700">
        <thead>
          <th
            v-for="thHeader in tableHeaders"
            :key="thHeader"
            class="py-4 ltr:pr-4 rtl:pl-4 text-left font-semibold text-slate-700 dark:text-slate-300"
          >
            {{ thHeader }}
          </th>
        </thead>
        <tbody
          class="divide-y divide-slate-50 dark:divide-slate-800 text-slate-700 dark:text-slate-300"
        >
          <WgptAutomationRow
            v-for="(automation, index) in records"
            :key="index"
            :automation="automation"
            :loading="loading[automation.id]"
            @edit="editAutomation(automation)"
            @clone="cloneAutomation(automation.id)"
            @delete="openDeletePopup(automation, index)"
            @toggle="toggleAutomation(automation, automation.active)"
          />
        </tbody>
      </table>
    </template>

    <woot-modal v-model:show="showAddPopup" @close="hideAddPopup">
      <AddAutomation v-if="showAddPopup" @close="hideAddPopup" />
    </woot-modal>

    <woot-delete-modal
      v-model:show="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('WGPT_AUTOMATIONS.DELETE.CONFIRM.TITLE')"
      :message="$t('WGPT_AUTOMATIONS.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />

    <woot-confirm-modal
      ref="confirmDialog"
      :title="toggleModalTitle"
      :description="toggleModalDescription"
    />
  </SettingsLayout>
</template>
