import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import BoardListCardsAPI from '../../api/wgpt/boardListCards';
import boardsAPI from '../../api/wgpt/boards';
import { throwErrorMessage } from '../utils/api';
import { filterHandler } from '../utils/wgpt';

export const state = {
  records: [],
  activeRecord: {},
  newRecordIds: [],
  hasMoreFlags: {},
  filters: {},
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isSearching: false,
  },
};

export const getters = {
  getCards: $state => (boardId, listId) => {
    return $state.records.filter(
      record =>
        record.boardId === Number(boardId) && record.listId === Number(listId)
    );
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getCard: $state => cardId => {
    return $state.records.find(record => record.id === Number(cardId));
  },
  getActiveCard($state) {
    return $state.activeRecord;
  },
  hasMoreCards: $state => listId => {
    return $state.hasMoreFlags[listId];
  },
  getFilters: $state => boardId => {
    return $state.filters[boardId] ?? {};
  },
  hasFilters: $state => boardId => {
    const filters = $state.filters[boardId] ?? {};
    const {
      members = [],
      contacts = [],
      labels = [],
      dates = [],
      search = '',
    } = filters;
    return (
      members.length > 0 ||
      contacts.length > 0 ||
      labels.length > 0 ||
      dates.length > 0 ||
      !!search
    );
  },
};

export const actions = {
  get: async ({ commit }, { boardId, listId, limit, offset, filters = {} }) => {
    const isSearching = !!filters.search;
    commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, {
      isFetching: true,
      isSearching,
    });
    try {
      const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
      const response = await boardListCardsAPI.get({
        limit,
        offset,
        filters,
      });
      const {
        payload,
        meta: { has_more: hasMore },
      } = response.data;
      const data = payload.map(
        ({ board_id, list_id, original_id, ...card }) => {
          card.boardId = board_id;
          card.listId = list_id;
          card.originalId = original_id;
          return card;
        }
      );
      if (offset === 0) {
        commit(types.SET_WGPT_BOARD_LIST_CARDS, { listId, hasMore, data });
      } else {
        commit(types.ADD_WGPT_BOARD_LIST_CARDS, { listId, hasMore, data });
      }

      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isFetching: false });
      return hasMore;
    } catch (error) {
      // Ignore error
      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isFetching: false });
      return true;
    }
  },
  create: async ({ commit }, { boardId, listId, ...cardObj }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isCreating: true });
    try {
      const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
      const {
        data: { board_id, list_id, original_id, ...data },
      } = await boardListCardsAPI.create(cardObj);
      data.boardId = board_id;
      data.listId = list_id;
      data.originalId = original_id;
      data.skipFilters = true;
      commit(types.ADD_WGPT_BOARD_LIST_CARD, data);
      return data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  update: async ({ commit }, { boardId, listId, id, ...cardObj }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isUpdating: true });
    try {
      const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
      const {
        data: { board_id, list_id, original_id, ...data },
      } = await boardListCardsAPI.update(id, cardObj);
      data.boardId = board_id;
      data.listId = list_id;
      data.originalId = original_id;
      commit(types.EDIT_WGPT_BOARD_LIST_CARD, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isUpdating: false });
    }
  },
  updateLabels: async (
    { commit },
    { boardId, listId, id, originalId, labels }
  ) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isUpdating: true });
    try {
      const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
      const { data } = await boardListCardsAPI.updateLabels(id, labels);
      const card = {
        id: Number(id),
        originalId: Number(originalId),
        boardId: Number(boardId),
        listId: Number(listId),
        labels: data.payload,
      };
      commit(types.EDIT_WGPT_BOARD_LIST_CARD, card);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, { boardId, listId, id }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isDeleting: true });
    try {
      const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
      await boardListCardsAPI.delete(id);
      commit(types.DELETE_WGPT_BOARD_LIST_CARD, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isDeleting: false });
    }
  },
  show: async ({ commit }, { boardId, listId, id }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isFetchingItem: true });
    try {
      const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
      const {
        data: { board_id, list_id, original_id, ...data },
      } = await boardListCardsAPI.show(id);
      data.boardId = board_id;
      data.listId = list_id;
      data.originalId = original_id;
      commit(types.ADD_WGPT_BOARD_LIST_CARD, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isFetchingItem: false });
    }
  },
  loadSelectedCard: async ({ commit }, { boardId, id }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isFetchingItem: true });
    commit(types.SET_WGPT_BOARD_LIST_CARD_ACTIVE, {});
    try {
      const {
        data: { board_id, list_id, original_id, ...data },
      } = await boardsAPI.showCard(boardId, id);
      data.boardId = board_id;
      data.listId = list_id;
      data.originalId = original_id;
      commit(types.SET_WGPT_BOARD_LIST_CARD_ACTIVE, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isFetchingItem: false });
    }
  },
  setActiveCard: ({ commit }, data) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_ACTIVE, data);
  },
  clear: ({ commit }) => {
    commit(types.EMPTY_WGPT_BOARD_LIST_CARD);
  },
  setBoardCards({ commit }, { listId, data }) {
    commit(types.SET_WGPT_BOARD_LIST_CARDS, { listId, data });
  },
  addBoardCard({ commit }, data) {
    commit(types.ADD_WGPT_BOARD_LIST_CARD, data);
  },
  updateBoardCard({ commit }, data) {
    commit(types.EDIT_WGPT_BOARD_LIST_CARD, data);
  },
  deleteBoardCard({ commit }, { id }) {
    commit(types.DELETE_WGPT_BOARD_LIST_CARD, Number(id));
  },
  deleteBoardCardByOriginalId({ commit }, { originalId }) {
    commit(
      types.DELETE_WGPT_BOARD_LIST_CARD_BY_ORIGINAL_ID,
      Number(originalId)
    );
  },
  addAttachment: async ({ commit }, { boardId, listId, id, file }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isUpdating: true });
    try {
      const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await boardListCardsAPI.addAttachment(id, formData);
      const { attachments } = data;
      commit(types.ADD_WGPT_BOARD_LIST_CARD_ATTACHMENT, {
        cardId: id,
        attachments,
      });
      return attachments[attachments.length - 1];
    } catch (error) {
      return throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isUpdating: false });
    }
  },
  removeAttachment: async ({ commit }, { boardId, listId, cardId, id }) => {
    const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
    await boardListCardsAPI.removeAttachment(cardId, id);
    commit(types.DELETE_WGPT_BOARD_LIST_CARD_ATTACHMENT, { cardId, id });
  },
  filter: ({ commit }, { boardId, filters }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARDS_FILTER, { boardId, filters });
  },
  clearFilters: ({ commit }, boardId) => {
    commit(types.SET_WGPT_BOARD_LIST_CARDS_FILTER, { boardId });
  },
};

export const mutations = {
  [types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.EMPTY_WGPT_BOARD_LIST_CARD]($state) {
    $state.records = [];
    $state.newRecordIds = [];
  },
  [types.ADD_WGPT_BOARD_LIST_CARD]($state, { skipFilters, ...data }) {
    if (skipFilters) {
      $state.newRecordIds.push(data.id);
    } else {
      const checkFilter = filterHandler(data, $state.filters[data.boardId]);
      if (!checkFilter) return;
    }

    const highestOrder = Math.max(
      ...$state.records
        .filter(({ listId }) => listId === data.listId)
        .map(({ order }) => order)
    );
    if (data.order > highestOrder && $state.hasMoreFlags[data.listId]) {
      return;
    }
    MutationHelpers.setSingleRecord($state, data);
  },
  [types.SET_WGPT_BOARD_LIST_CARDS]($state, { listId, hasMore, data }) {
    listId = Number(listId);
    const ids = data.map(({ id }) => id);
    $state.records = $state.records
      .filter(record => {
        if (ids.includes(record.id)) return false;
        if (record.listId === listId) return false;
        return true;
      })
      .concat(
        ...data.map(d => {
          d.listIdBefore = d.listId;
          d.listId = listId;
          return d;
        })
      );
    if (hasMore != null) {
      $state.hasMoreFlags[listId] = hasMore;
    }
  },
  [types.SET_WGPT_BOARD_LIST_CARD_ACTIVE]($state, data) {
    $state.activeRecord = data;
  },
  [types.ADD_WGPT_BOARD_LIST_CARDS]($state, { listId, hasMore, data }) {
    listId = Number(listId);
    const ids = data.map(({ id }) => id);
    $state.records = $state.records
      .filter(record => {
        if (ids.includes(record.id)) return false;
        return true;
      })
      .concat(
        ...data.map(d => {
          d.listIdBefore = d.listId;
          d.listId = listId;
          return d;
        })
      );
    if (hasMore != null) {
      $state.hasMoreFlags[listId] = hasMore;
    }
  },
  [types.EDIT_WGPT_BOARD_LIST_CARD]($state, data) {
    if ($state.activeRecord.originalId === data.originalId) {
      $state.activeRecord = { ...$state.activeRecord, ...data };
    }

    const highestOrder = Math.max(
      ...$state.records
        .filter(({ listId }) => listId === data.listId)
        .map(({ order }) => order)
    );

    const dataBefore = $state.records.find((record, index) => {
      if (record.id !== data.id) return false;
      $state.records[index] = { ...record, ...data };
      return true;
    });

    const checkFilter = filterHandler(data, $state.filters[data.boardId]);
    if (!checkFilter) {
      const { newRecordIds } = $state;
      if (dataBefore && !newRecordIds.includes(data.id)) {
        $state.records = $state.records.filter(record => record.id !== data.id);
      }
      return;
    }

    if (!dataBefore) {
      // This case to cover when the card is moved to the board
      if (data.order > highestOrder && $state.hasMoreFlags[data.listId]) {
        return;
      }
      MutationHelpers.setSingleRecord($state, data);
      $state.records.forEach(record => {
        if (record.listId !== data.listId) return;
        if (record.id === data.id) return;
        if (record.order >= data.order) {
          record.order += 1;
        }
      });
      $state.records = $state.records.sort((r1, r2) => r1.order - r2.order);
      return;
    }

    if (data.order > highestOrder && $state.hasMoreFlags[data.listId]) {
      $state.records = $state.records.filter(record => record.id !== data.id);
    }

    if (
      dataBefore.listIdBefore
        ? data.listId !== dataBefore.listIdBefore
        : data.listId !== dataBefore.listId
    ) {
      $state.records.forEach(record => {
        if (record.listId !== data.listId) return;
        if (record.id === data.id) return;
        if (record.order >= data.order) {
          record.order += 1;
        }
      });
    } else if (data.order !== dataBefore.order) {
      if (data.order > dataBefore.order) {
        $state.records.forEach(record => {
          if (record.listId !== data.listId) return;
          if (record.id === data.id) return;
          if (record.order > dataBefore.order && record.order <= data.order) {
            record.order -= 1;
          }
        });
      } else if (data.order < dataBefore.order) {
        $state.records.forEach(record => {
          if (record.listId !== data.listId) return;
          if (record.id === data.id) return;
          if (record.order >= data.order && record.order < dataBefore.order) {
            record.order += 1;
          }
        });
      }
    }
    $state.records = $state.records.sort(
      (recordA, recordB) => recordA.order - recordB.order
    );
  },
  [types.DELETE_WGPT_BOARD_LIST_CARD]: MutationHelpers.destroy,
  [types.DELETE_WGPT_BOARD_LIST_CARD_BY_ORIGINAL_ID]($state, originalId) {
    $state.records = $state.records.filter(
      record => record.originalId !== originalId
    );
  },
  [types.ADD_WGPT_BOARD_LIST_CARD_ATTACHMENT]($state, { cardId, attachments }) {
    if ($state.activeRecord.id === cardId) {
      $state.activeRecord = { ...$state.activeRecord, attachments };
    }

    $state.records.forEach((record, index) => {
      if (record.id === cardId) {
        $state.records[index] = { ...record, attachments };
      }
    });
  },
  [types.DELETE_WGPT_BOARD_LIST_CARD_ATTACHMENT]($state, { cardId, id }) {
    if ($state.activeRecord.id === cardId) {
      const attachmentsBefore = $state.activeRecord.attachments || [];
      const attachments = attachmentsBefore.filter(
        attachment => attachment.id !== id
      );
      $state.activeRecord = { ...$state.activeRecord, attachments };
    }

    $state.records.forEach((record, index) => {
      if (record.id === cardId) {
        const attachmentsBefore = record.attachments || [];
        const attachments = attachmentsBefore.filter(
          attachment => attachment.id !== id
        );
        $state.records[index] = { ...record, attachments };
      }
    });
  },
  [types.SET_WGPT_BOARD_LIST_CARDS_FILTER]($state, { boardId, filters }) {
    $state.filters = {
      ...$state.filters,
      [boardId]: filters,
    };
    $state.newRecordIds = [];
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
