export class WgptActionCableEvents {
  constructor(app) {
    this.app = app;
    this.events = {
      'wgpt_board.created': this.onWgptBoardCreated,
      'wgpt_board.updated': this.onWgptBoardUpdated,
      'wgpt_board.deleted': this.onWgptBoardDeleted,
      'wgpt_board_list.created': this.onWgptBoardListCreated,
      'wgpt_board_list.updated': this.onWgptBoardListUpdated,
      'wgpt_board_list.deleted': this.onWgptBoardListDeleted,
      'wgpt_board_list_card.created': this.onWgptBoardCardCreated,
      'wgpt_board_list_card.updated': this.onWgptBoardCardUpdated,
      'wgpt_board_list_card.deleted': this.onWgptBoardCardDeleted,
      'wgpt_board_list_card_activity.created':
        this.onWgptBoardCardActivityCreated,
      'wgpt_board_list_card_activity.updated':
        this.onWgptBoardCardActivityUpdated,
      'wgpt_board_list_card_activity.deleted':
        this.onWgptBoardCardActivityDeleted,
    };
  }

  onWgptBoardCreated = data => {
    this.app.$store.dispatch('wgptBoards/addBoard', data);
  };

  onWgptBoardUpdated = data => {
    this.app.$store.dispatch('wgptBoards/updateBoard', data);
  };

  onWgptBoardDeleted = data => {
    this.app.$store.dispatch('wgptBoards/deleteBoard', data);
  };

  onWgptBoardListCreated = ({ board_id: boardId, ...data }) => {
    if (!this.isAValidEventPerformer(data)) return;
    if (!this.isBoardActive(boardId)) return;
    this.app.$store.dispatch('wgptBoardLists/addBoardList', {
      boardId,
      ...data,
    });
  };

  onWgptBoardListUpdated = ({ board_id: boardId, ...data }) => {
    if (!this.isAValidEventPerformer(data)) return;
    if (!this.isBoardActive(boardId)) return;
    this.app.$store.dispatch('wgptBoardLists/updateBoardList', {
      boardId,
      ...data,
    });
  };

  onWgptBoardListDeleted = ({ board_id: boardId, id, ...data }) => {
    if (!this.isAValidEventPerformer(data)) return;
    if (!this.isBoardActive(boardId)) return;
    this.app.$store.dispatch('wgptBoardLists/deleteBoardList', id);
  };

  onWgptBoardCardCreated = ({
    board_id: boardId,
    list_id: listId,
    original_id: originalId,
    ...data
  }) => {
    if (!this.isAValidEventPerformer(data)) return;
    if (!this.isBoardActive(boardId)) return;
    this.app.$store.dispatch('wgptBoardListCards/addBoardCard', {
      boardId,
      listId,
      originalId,
      ...data,
    });
  };

  onWgptBoardCardUpdated = ({
    board_id: boardId,
    list_id: listId,
    original_id: originalId,
    ...data
  }) => {
    if (!this.isAValidEventPerformer(data)) return;
    if (this.isBoardActive(boardId)) {
      this.app.$store.dispatch('wgptBoardListCards/updateBoardCard', {
        boardId,
        listId,
        originalId,
        ...data,
      });
    } else {
      this.app.$store.dispatch(
        'wgptBoardListCards/deleteBoardCardByOriginalId',
        { originalId }
      );
    }
  };

  onWgptBoardCardDeleted = ({ board_id: boardId, ...data }) => {
    if (!this.isAValidEventPerformer(data)) return;
    if (!this.isBoardActive(boardId)) return;
    this.app.$store.dispatch('wgptBoardListCards/deleteBoardCard', {
      id: data.id,
    });
  };

  onWgptBoardCardActivityCreated = ({
    board_id: boardId,
    card_id: cardId,
    ...data
  }) => {
    if (!this.isAValidEventPerformer(data)) return;
    if (!this.isBoardActive(boardId)) return;
    if (!this.isCardActive(cardId)) return;
    this.app.$store.dispatch('wgptBoardListCardActivities/addActivity', {
      boardId,
      cardId,
      ...data,
    });
  };

  onWgptBoardCardActivityUpdated = ({
    board_id: boardId,
    card_id: cardId,
    ...data
  }) => {
    if (!this.isAValidEventPerformer(data)) return;
    if (!this.isBoardActive(boardId)) return;
    if (!this.isCardActive(cardId)) return;
    this.app.$store.dispatch('wgptBoardListCardActivities/updateActivity', {
      boardId,
      cardId,
      ...data,
    });
  };

  onWgptBoardCardActivityDeleted = ({
    board_id: boardId,
    card_id: cardId,
    id,
    ...data
  }) => {
    if (!this.isAValidEventPerformer(data)) return;
    if (!this.isBoardActive(boardId)) return;
    if (!this.isCardActive(cardId)) return;
    this.app.$store.dispatch('wgptBoardListCardActivities/deleteActivity', id);
  };

  // eslint-disable-next-line class-methods-use-this
  isBoardActive = id => {
    const path = window.location.pathname;
    if (!path.includes('wgpt-boards')) return false;
    const paths = path.split('/');
    const boardIdParam = Number(paths[paths.indexOf('wgpt-boards') + 1]);
    return boardIdParam === id;
  };

  // eslint-disable-next-line class-methods-use-this
  isCardActive = id => {
    const path = window.location.pathname;
    if (!path.includes('wgpt-boards')) return false;
    const selectedCard = new URLSearchParams(window.location.search).get(
      'selectedCard'
    );
    return Number(selectedCard) === id;
  };

  isAValidEventPerformer = data => {
    return this.app.$store.getters.getCurrentUserID !== data?.performer?.id;
  };
}

export default WgptActionCableEvents;
