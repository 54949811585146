/* global axios */
import ApiClient from '../ApiClient';

class WgptBoardsAPI extends ApiClient {
  constructor() {
    super('boards', { accountScoped: true, wgpt: true });
  }

  showCard(boardId, cardId) {
    return axios.get(`${this.url}/${boardId}/cards/${cardId}`);
  }
}

export default new WgptBoardsAPI();
