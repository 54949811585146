import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import AutomationsAPI from '../../api/wgpt/automations';
import { throwErrorMessage } from '../utils/api';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isCloning: false,
  },
};

export const getters = {
  getAutomations($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getAutomation: $state => automationId => {
    const automation = $state.records.find(
      record => record.id === Number(automationId)
    );
    return automation || {};
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_WGPT_AUTOMATION_UI_FLAG, { isFetching: true });
    try {
      const response = await AutomationsAPI.get();
      commit(types.SET_WGPT_AUTOMATIONS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WGPT_AUTOMATION_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, automationObj) => {
    commit(types.SET_WGPT_AUTOMATION_UI_FLAG, { isCreating: true });
    try {
      const response = await AutomationsAPI.create(automationObj);
      commit(types.ADD_WGPT_AUTOMATION, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_AUTOMATION_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  update: async ({ commit }, { id, ...automationObj }) => {
    commit(types.SET_WGPT_AUTOMATION_UI_FLAG, { isUpdating: true });
    try {
      const response = await AutomationsAPI.update(id, automationObj);
      commit(types.EDIT_WGPT_AUTOMATION, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_AUTOMATION_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_WGPT_AUTOMATION_UI_FLAG, { isDeleting: true });
    try {
      await AutomationsAPI.delete(id);
      commit(types.DELETE_WGPT_AUTOMATION, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_AUTOMATION_UI_FLAG, { isDeleting: false });
    }
  },
  show: async ({ commit }, id) => {
    commit(types.SET_WGPT_AUTOMATION_UI_FLAG, { isFetchingItem: true });
    try {
      const { data } = await AutomationsAPI.show(id);
      commit(types.ADD_WGPT_AUTOMATION, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_AUTOMATION_UI_FLAG, { isFetchingItem: false });
    }
  },
  clone: async ({ commit }, id) => {
    commit(types.SET_WGPT_AUTOMATION_UI_FLAG, { isCloning: true });
    try {
      await AutomationsAPI.clone(id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_WGPT_AUTOMATION_UI_FLAG, { isCloning: false });
    }
  },
  addFile: async ({ commit }, { id: automationId, file }) => {
    commit(types.SET_WGPT_AUTOMATION_UI_FLAG, { isUpdating: true });
    try {
      const id = Number(automationId);
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await AutomationsAPI.addFile(id, formData);
      const { files } = data;
      commit(types.EDIT_WGPT_AUTOMATION, { id, files });
      return files[files.length - 1];
    } catch (error) {
      return throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_AUTOMATION_UI_FLAG, { isUpdating: false });
    }
  },
  removeFile: async ({ commit }, { id, fileId }) => {
    await AutomationsAPI.removeFile(id, fileId);
    commit(types.REMOVE_WGPT_AUTOMATION_FILE, { id, fileId });
  },
};

export const mutations = {
  [types.SET_WGPT_AUTOMATION_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.ADD_WGPT_AUTOMATION]: MutationHelpers.setSingleRecord,
  [types.SET_WGPT_AUTOMATIONS]: MutationHelpers.set,
  [types.EDIT_WGPT_AUTOMATION]: MutationHelpers.update,
  [types.DELETE_WGPT_AUTOMATION]: MutationHelpers.destroy,
  [types.REMOVE_WGPT_AUTOMATION_FILE]($state, { id, fileId }) {
    $state.records.forEach((record, index) => {
      if (record.id === id) {
        const filesBefore = record.files || [];
        const files = filesBefore.filter(file => file.id !== fileId);
        $state.records = {
          ...$state.records,
          [index]: { ...record, files },
        };
      }
    });
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
