/* global axios */
import ApiClient from '../ApiClient';

class WgptBoardListCardActivitiesAPI extends ApiClient {
  constructor(boardId, listId, cardId) {
    super(`boards/${boardId}/lists/${listId}/cards/${cardId}/activities`, {
      accountScoped: true,
      wgpt: true,
    });
  }

  get({ limit, offset }) {
    return axios.get(this.url, { params: { limit, offset } });
  }
}

export default WgptBoardListCardActivitiesAPI;
