/* global axios */
import ApiClient from '../ApiClient';

class WgptAutomationsAPI extends ApiClient {
  constructor() {
    super('automations', {
      accountScoped: true,
      wgpt: true,
    });
  }

  clone(automationId) {
    return axios.post(`${this.url}/${automationId}/clone`);
  }

  addFile(automationId, data) {
    return axios.post(`${this.url}/${automationId}/files`, data);
  }
}

export default new WgptAutomationsAPI();
