import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import BoardListCardActivitiesAPI from '../../api/wgpt/boardListCardActivities';
import { throwErrorMessage } from '../utils/api';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    hasMore: false,
  },
};

export const getters = {
  getActivities: $state => (listId, cardId) => {
    return $state.records.filter(
      record =>
        record.listId === Number(listId) && record.cardId === Number(cardId)
    );
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getActivity: $state => id => {
    const [activity] = $state.records.filter(
      record => record.id === Number(id)
    );
    return activity || {};
  },
};

export const actions = {
  get: async ({ commit }, { boardId, listId, cardId, offset, limit }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_ACTIVITY_UI_FLAG, {
      isFetching: true,
    });
    try {
      const boardListCardActivitiesAPI = new BoardListCardActivitiesAPI(
        boardId,
        listId,
        cardId
      );
      const response = await boardListCardActivitiesAPI.get({ offset, limit });
      const data = response.data;
      const results = data.payload.map(activity => {
        activity.cardId = cardId;
        activity.listId = listId;
        return activity;
      });
      if (offset === 0) {
        commit(types.SET_WGPT_BOARD_LIST_CARD_ACTIVITIES, results);
      } else {
        commit(types.ADD_WGPT_BOARD_LIST_CARD_ACTIVITIES, results);
      }
      commit(types.SET_WGPT_BOARD_LIST_CARD_ACTIVITY_UI_FLAG, {
        isFetching: false,
        hasMore: data.meta.has_more,
      });
    } catch (error) {
      // Ignore error
      commit(types.SET_WGPT_BOARD_LIST_CARD_ACTIVITY_UI_FLAG, {
        isFetching: false,
      });
    }
  },
  create: async ({ commit }, { boardId, listId, cardId, ...activityObj }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_ACTIVITY_UI_FLAG, {
      isCreating: true,
    });
    try {
      const boardListCardActivitiesAPI = new BoardListCardActivitiesAPI(
        boardId,
        listId,
        cardId
      );
      const { data } = await boardListCardActivitiesAPI.create(activityObj);
      data.cardId = cardId;
      data.listId = listId;
      commit(types.ADD_WGPT_BOARD_LIST_CARD_ACTIVITY, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_ACTIVITY_UI_FLAG, {
        isCreating: false,
      });
    }
    return null;
  },
  update: async (
    { commit },
    { boardId, listId, cardId, id, ...activityObj }
  ) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_ACTIVITY_UI_FLAG, {
      isUpdating: true,
    });
    try {
      const boardListCardActivitiesAPI = new BoardListCardActivitiesAPI(
        boardId,
        listId,
        cardId
      );
      const { data } = await boardListCardActivitiesAPI.update(id, activityObj);
      data.cardId = cardId;
      data.listId = listId;
      commit(types.EDIT_WGPT_BOARD_LIST_CARD_ACTIVITY, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_ACTIVITY_UI_FLAG, {
        isUpdating: false,
      });
    }
  },
  delete: async ({ commit }, { boardId, listId, cardId, id }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_ACTIVITY_UI_FLAG, {
      isDeleting: true,
    });
    try {
      const boardListCardActivitiesAPI = new BoardListCardActivitiesAPI(
        boardId,
        listId,
        cardId
      );
      await boardListCardActivitiesAPI.delete(id);
      commit(types.DELETE_WGPT_BOARD_LIST_CARD_ACTIVITY, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_ACTIVITY_UI_FLAG, {
        isDeleting: false,
      });
    }
  },
  show: async ({ commit }, { boardId, listId, cardId, id }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_ACTIVITY_UI_FLAG, {
      isFetchingItem: true,
    });
    try {
      const boardListCardActivitiesAPI = new BoardListCardActivitiesAPI(
        boardId,
        listId,
        cardId
      );
      const { data } = await boardListCardActivitiesAPI.show(id);
      data.cardId = cardId;
      data.listId = listId;
      commit(types.ADD_WGPT_BOARD_LIST_CARD_ACTIVITY, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_ACTIVITY_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },
  addActivity({ commit }, { list_id: listId, ...data }) {
    commit(types.ADD_WGPT_BOARD_LIST_CARD_ACTIVITY, { listId, ...data });
  },
  updateActivity({ commit }, { list_id: listId, ...data }) {
    commit(types.EDIT_WGPT_BOARD_LIST_CARD_ACTIVITY, { listId, ...data });
  },
  deleteActivity({ commit }, id) {
    commit(types.DELETE_WGPT_BOARD_LIST_CARD_ACTIVITY, id);
  },
  clearActivity({ commit }) {
    commit(types.EMPTY_WGPT_BOARD_LIST_CARD_ACTIVITY);
  },
};

export const mutations = {
  [types.SET_WGPT_BOARD_LIST_CARD_ACTIVITY_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.EMPTY_WGPT_BOARD_LIST_CARD_ACTIVITY]($state) {
    $state.records = [];
  },
  [types.ADD_WGPT_BOARD_LIST_CARD_ACTIVITY]($state, data) {
    $state.records.unshift(data);
  },
  [types.SET_WGPT_BOARD_LIST_CARD_ACTIVITIES]: MutationHelpers.set,
  [types.ADD_WGPT_BOARD_LIST_CARD_ACTIVITIES]($state, data) {
    $state.records.push(...data);
  },
  [types.EDIT_WGPT_BOARD_LIST_CARD_ACTIVITY]: MutationHelpers.update,
  [types.DELETE_WGPT_BOARD_LIST_CARD_ACTIVITY]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
