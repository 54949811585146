const filterBySearch = (card, searchFilter = '') => {
  const searchQuery = searchFilter.trim();
  if (!searchQuery) return true;

  const cardName = card.name ?? '';
  return cardName.toLowerCase().includes(searchQuery);
};

const filterByMembers = (card, membersFilter = []) => {
  if (!membersFilter.length) return true;
  const cardMembers = card.config.members ?? [];
  return membersFilter.some(id => cardMembers.includes(id));
};

const filterByContacts = (card, contactsFilter = []) => {
  if (!contactsFilter.length) return true;
  const cardContacts = card.config.contacts ?? [];
  return contactsFilter.some(id => cardContacts.includes(id));
};

const filterByLabels = (card, labelsFilter = []) => {
  if (!labelsFilter.length) return true;
  const cardLabels = card.labels ?? [];
  return labelsFilter.some(label => cardLabels.includes(label));
};

const filterByDate = (card, dateFilter) => {
  const now = new Date();
  const endDate = card.end_date ? new Date(card.end_date) : undefined;

  switch (dateFilter) {
    case 'no_dates':
      return !card.complete && !endDate && !card.resolved_at;
    case 'overdue':
      return !card.complete && endDate && endDate < now && !card.resolved_at;
    case 'due_next_day': {
      const tomorrow = new Date(now.getTime());
      tomorrow.setHours(23, 59, 59, 999);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return (
        !card.complete &&
        endDate &&
        endDate >= now &&
        endDate <= tomorrow &&
        !card.resolved_at
      );
    }
    case 'due_next_week': {
      const nextWeek = new Date(now.getTime());
      nextWeek.setHours(23, 59, 59, 999);
      nextWeek.setDate(nextWeek.getDate() + 7);
      return (
        !card.complete &&
        endDate &&
        endDate >= now &&
        endDate <= nextWeek &&
        !card.resolved_at
      );
    }
    case 'due_next_month': {
      const nextMonth = new Date(now.getTime());
      nextMonth.setHours(23, 59, 59, 999);
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      return (
        !card.complete &&
        endDate &&
        endDate >= now &&
        endDate <= nextMonth &&
        !card.resolved_at
      );
    }
    case 'complete':
      return card.complete || card.resolved_at;
    case 'not_complete':
      return !(card.complete || card.resolved_at);
    default:
      return false;
  }
};

const filterByDates = (card, datesFilter = []) => {
  if (!datesFilter.length) return true;
  return datesFilter.some(dateFilter => filterByDate(card, dateFilter));
};

export const filterHandler = (card, filters = {}) => {
  if (Object.keys(filters).length === 0) return true;

  return (
    filterBySearch(card, filters.search) &&
    filterByMembers(card, filters.members) &&
    filterByContacts(card, filters.contacts) &&
    filterByLabels(card, filters.labels) &&
    filterByDates(card, filters.dates)
  );
};
