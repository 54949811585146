/* global axios */
import ApiClient from '../ApiClient';

class WgptAutomationRegistryAPI extends ApiClient {
  constructor() {
    super('automation_registry', {
      accountScoped: true,
      wgpt: true,
    });
  }

  getDynamicOptions(fieldName, params) {
    return axios.get(`${this.url}/dynamic_options/${fieldName}`, { params });
  }

  getCustomObjects(objectName) {
    return axios.get(`${this.url}/custom_objects/${objectName}`);
  }

  getTriggers() {
    return axios.get(`${this.url}/triggers`);
  }

  getActions() {
    return axios.get(`${this.url}/actions`);
  }

  getActionsForTrigger(triggerId) {
    return axios.get(`${this.url}/triggers/${triggerId}/actions`);
  }

  getLogicalOperators() {
    return axios.get(`${this.url}/logical_operators`);
  }

  getComparisonOperators() {
    return axios.get(`${this.url}/comparison_operators`);
  }
}

export default new WgptAutomationRegistryAPI();
