import WgptAutomations from './Index.vue';
const Editor = () => import('./editor/Index.vue');

import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');
const SettingsWrapper = () => import('../SettingsWrapper.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/wgpt-automations'),
      component: SettingsWrapper,
      children: [
        {
          path: '',
          name: 'wgpt_automations',
          redirect: 'list',
          meta: {
            permissions: ['administrator'],
          },
        },
        {
          path: 'list',
          name: 'wgpt_automations_list',
          component: WgptAutomations,
          meta: {
            permissions: ['administrator'],
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/settings/wgpt-automations'),
      component: SettingsContent,
      props: route => {
        const showBackButton = route.name !== 'wgpt_automations_list';
        return {
          headerTitle: 'WGPT_AUTOMATIONS.HEADER',
          headerButtonText: 'WGPT_AUTOMATIONS.HEADER_BTN_TXT',
          icon: 'flash-on',
          showBackButton,
          showNewButton: false,
        };
      },
      children: [
        {
          path: ':automationId/edit',
          name: 'wgpt_automations_edit',
          component: Editor,
          meta: {
            permissions: ['administrator'],
          },
        },
      ],
    },
  ],
};
